#RFS-StepButton {
    width: 12.5rem !important;
    height: 2.5rem !important;
}

#RFS-Connector
{
    margin-top:-2.2rem !important;
}

#RFS-StepperContainer {
    margin-top: 0px !important;
    padding-top : 0px !important
}