/*
    background color for menu
    Level 1     :   backgroun colour : #0c3f62, font colour : #fff
    Level 2     :   backgroun colour : #5faed2, font colour : #fff
    Level 3     :   backgroun colour : #adb3b8, font colour : #fff
    
*/

:root {
    --Level1: #0c3f62;
    --Level2: #5faed2;
    --Level3: #adb3b8;
    --MenuFontColour: #fff;
}

body {
    font: normal arial,verdana,tahoma,sans-serif;
    color: #000;
    margin: 0;
    padding: 0;
    background: #fff;
}

div, p, ul, ol {
    margin: 0;
    padding: 0;
}

/* Main Container Div */
#outermostDiv {
    width: 100%;
    height: auto;
    margin: 0 auto;
    z-index: -10;
}

/* Outside Menu Wrapper */
#mainWrapper {
    width: 100%;
    z-index: 3000;
}


/* Container for Menus */
#menu {
    width: 100%;
    height: 40px;
    background: var(--Level1);
    z-index: 1000;
}


#secondLineDiv {
    width: 100%;
    height: 40px;
    background: var(--Level2);
    z-index: -7;
}

#thirdLineDiv {
    width: 100%;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #d7d5d5;
    z-index: -5;
}


/* Options for the logo on the home page 
}*/

#logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 90px;
    background: #fff;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #d7d5d5;
    overflow: hidden;
}

    #logo img {
        border: none;
    }

#locationDiv {
    position: absolute;
    left: 0;
    top: 80px;
    width: 200px;
    height: 40px;
    background: #fff;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #d7d5d5;
}

    #locationDiv p {       
        text-align: center;
        font-size: 10pt;
        font-weight: bold;
    }
/*Default options for the menu at different levels*/
ul#nav {
    position: absolute;
    left: 200px;
    top: 0;
    margin: 0;
    padding: 0;
    font-size: 70%;
    list-style: none;
    background: var(--Level1);
    z-index: 0;
    font-size: 80%;
}

    ul#nav ul {
        min-width: 100%;
    }

    ul#nav li {
        display: inline;
    }


    ul#nav ul ul li {
        display: block;
    }

    ul#nav ul ul {
        border: 1px solid #aaa;
        padding: 2px;
        border-radius: .2em;
        -moz-border-radius: .2em;
        -webkit-border-radius: .2em;
        -webkit-box-shadow: 2px 2px 4px #666;
        -moz-box-shadow: 2px 2px 4px #666;
        box-shadow: 2px 2px 4px #666;
        color: #000;
    }

    /*Default Options for menus at level 1*/
    ul#nav li.Level1 a {
        float: left;
        font: bold 1.2em arial,verdana,tahoma,sans-serif;
        line-height: 50px;
        color: var(--MenuFontColour);
        text-decoration: none;
        margin: 0;
        padding: 0 17px;
        background: var(--Level1);
    }

.backgroundLevel1 {
    background-color: var(--Level1);
}

.SelectBGLevel1 {
    background-color: var(--Level2) !important;
}

    /*Default Options for menus at level 2*/
ul#nav li.Level2 a {
    float: left;
    font: normal 1.1em arial,verdana,tahoma,sans-serif;
    line-height: 40px;
    color: var(--MenuFontColour);
    text-decoration: none;
    margin: 0;
    padding: 0 9px;
    background: var(--Level2);
}

.backgroundLevel2 {
    background-color: var(--Level2);
}

.SelectBGLevel2 {
    background-color: var(--Level3) !important;
}

    /*Default Options for menus at level 3*/
ul#nav li.Level3 a {
    float: left;
    font: normal 1em arial,verdana,tahoma,sans-serif;
    line-height: 30px;
    color: var(--MenuFontColour);
    text-decoration: none;
    margin: 0;
    padding: 0 7px;
    background: var(--Level3);
    clear: left;
}

.backgroundLevel3 {
    background-color: var(--Level3);
}

    ul#nav li.Level4 a {
        float: left;
        font: normal 1em arial,verdana,tahoma,sans-serif;
        line-height: 30px;
        color: #000;
        text-decoration: none;
        margin: 0;
        padding: 0 10px;
        background: #e1e8ef;
        clear: left;
    }

    ul#nav ul {
        display: none;
    }

.highlightMenuItem {
    font-weight: bold;
}

#ddlProjectNames {
    float: right;
    width: 300px;
    margin: 2px 10px 0px 0px !important;
    display: block;
}

.fixedNav {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
}

.loggedInUserName {
    font-size: 13pt;
    display: inline-block;
    position: relative;
    float: right;
    margin-top: 0.2%;
    margin-right: 2%;
}
.menuItemText {
    font-size: 13.5pt !important;
}

.loggedInUserNameContextBtn {
    background-color: var(--Level2);
    color: var(--MenuFontColour);
}