.container {
    text-align: center;
}

.toggleswitch {
    position: relative;
    width: 80px;
    display: inline-block;
    text-align: left;
    top: 5px;
}

.checkbox {
    display: none;
}

.label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}

.inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

    .inner:before,
    .inner:after {
        float: left;
        width: 50%;
        height: 30px;
        padding: 0;
        line-height: 30px;
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
    }

    .inner:before {
        content: "Single";
        padding-left: 5px;
        background-color: #76a944;
        color: #fff;
    }

    .inner:after {
        content: "Dual";
        padding-right: 5px;
        background-color: #f15e25;
        color: #fff;
        text-align: right;
    }

.switch {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 7px;
    margin-bottom: 10px;
    background: #ffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
}

.checkbox:checked + .label .inner {
    margin-left: 0;
}

.checkbox:checked + .label .switch {
    right: 0px;
}
