.card {
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
}

.ml-auto, .mxauto {
    margin-left: auto !important;
}

.p4 {
    padding: .5rem !important;
}

.mt2, .my-2 {
    margin-top: 0.1rem !important;
}

.bglight {
    background-color: #f8f9fa !important;
}

.cardBorder {
    border: 1px solid #e5e5e5
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;   
}

.bglight {
    background-color: #f8f9fa !important;
}

.cardbody {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.1rem;
}