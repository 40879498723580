body {
    font: normal arial,verdana,tahoma,sans-serif;
    color: #000;
    margin: 0;
    padding: 0;
    background: #fff;
}
/* React.js modal popup classes*/
.modal {
    font-size: 18px;
}

.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal > .content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
}

.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}
.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
html[data-theme='dark'] .popup-content {
    background-color: rgb(41, 45, 62);
    color: #fff;
    border: 1px solid #9a9595;
}

html[data-theme='light'] .popup-content {
    background-color: #fff;
    color: #000;
    border: 1px solid #d7d7d7;
}

html[data-theme='dark'] .popup-arrow > svg {
    color: rgb(41, 45, 62);
    stroke-width: 2px;
    stroke: #9a9595;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
}

html[data-theme='light'] .popup-arrow > svg {
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
}

.rowstriped:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-width: thin;
    border-color: silver;
    padding-top: 3px;
    padding-bottom: 3px;
}

.rowstriped:nth-of-type(even) {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-width: thin;
    border-color: silver;
    padding-top: 3px;
    padding-bottom: 3px;
}
.TSModalDailog_95pc {
    max-width: 95% !important;
    width: 95% !important;
    margin-top: 140px !important;
}


.TSModalDailog_Two {
    max-width: 80% !important;
    width: 80% !important;
    margin-top: 140px !important;
}

.TSModalDailog_One {
    max-width: 80% !important;
    margin-top: 80px !important;    
}

.TSModalDailog_Three {
    max-width: 60% !important;
    width: 60%;
}

/*Table*/
table {
    border:thin solid;
    border-color:darkgrey;
    width: 100%;
    height: auto;
}
th tr {
    border-bottom: 1px solid black;   
}
td {
    text-align: center;
    border: thin solid;
    border-color: darkgrey;
    height: 40px;
}
.FirstLastColWidth {
    width : 10px;
}
.tbHeaderCaptionDark {
    font-weight: bold;
    background-color: #adb3b8;
    height: 35px;  
    color: white;
}

/*collapsible div*/
.collapsible .content {
    padding: 6px;
    background-color: #eeeeee;
}

.collapsible .header {
    background-color: #dddddd;
    padding: 6px;
    cursor: pointer;
}
.collapsibleHeader {
    border-top-style: solid;
    border-width: thin;
    border-color: silver;
    background: rgb(131, 192, 220);
    color: white;
    font-weight: bold;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 2px;
    height: 32px;
    padding-top: 0px;
    padding-left:15px
}
.collapsibleBodyBorder {
   border-style: solid;
   border-width: thin;
   border-color: silver;
}
/*End collapsible div*/

.reactTbLeftBorder {
    border-left-style: solid;
    border-width: thin;
    border-color: rgba(0, 0, 0, 0.12);
}
.reactTbRightBorder {
    border-right-style: solid;
    border-width: thin;
    border-color: rgba(0, 0, 0, 0.12);
}
.gridSearchPanel {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold
}
.popover {
    left: 83% !important;
}
.disable {
    opacity: 0.5;
    cursor: default;
}
.enable {
    opacity: 1;
    cursor: pointer;
}

.rowWhite {
    background-color: #ffffff;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-width: thin;
    border-color: silver;
    padding: 7px;
}

table > tr > td > select{
    border-width:1px !important;
}

.TSModalDailog_Two_Body {
    height: 600px;
    max-height: 600px;
    overflow-x:scroll
}

.act_icon{
    height : 34px;
    width  : 34px;
    cursor:pointer;
}
.collapsible_icon {  
    height: 30px;
    width: 30px;
    cursor: pointer;
    float:right;
}
.cellRightBorder {
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;    
}
.cellRLBBorder {
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}
.cellBorder {
    border: 1px solid #e5e5e5  
}

.BorderedContainer {
    border: solid;
    border-width: 1px;
    border-color: silver;
    margin: 10px;
    padding: 10px;
}

.TSModalDailog_Four {
    max-width: 20% !important;
    width: 20%;
}
/*Rich Text editor*/
.TSWrapper01 {
    border: 1px solid #e5e5e5;
    background-color: white;
    margin: 10px;
}
.TSEditor01 {
    height: auto;
}

.TSModalDailog_Five {
    max-width: 35% !important;
    width: 35%;
}

.TSModalDailog_Six {
    max-width: 48% !important;
    width: 48%;
}

.TSModalDailog_Seven {
    max-width: 40% !important;
    width: 40%;
}

.TSModalDailog_Eight {
    max-width: 30% !important;
    width: 30%;
}

.TSModalDailog_Nine {
    max-width: 20% !important;
    width: 20%;
}

.lblinfo {
    background-color: #CCDDFF;
    border: 1px solid Silver;
    border-bottom-color: silver;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    text-align: center;
    border-bottom: none;
    padding: 7px;
}

.pwdsecqinfo {
    background-color: #efefef;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
}

.lblcol {
    background-color: #EFF3F8;
    padding: 3px;
    border-right: 1px solid Silver;
}

.fldcol {
    background-color: #FFFFFF;
    padding-top: 4px;
    padding-bottom: 4px;
}

.textWrap {
    word-wrap: break-word !important;
    white-space: pre-wrap;
}

.TSModalDailog_Ten {
    max-width: 35% !important;
    width: 35% !important;
    margin-top: 140px !important;
}

.label_One {
    font-weight:400;
    font-size:18px;
    font-style:normal;
   
}

.modalHeader {
    font-weight: bold;
    background-color: #5faed2;
    color: white;
    padding: 9px !important;
}

.TSModalDailog_Eleven {
    max-width: 35% !important;
    width: 35% !important;
    margin-top: 140px !important;
}

.wrapperclass {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editorclass {
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbarclass {
  border: 1px solid #ccc;
}

.TSModalDailog_Twelve {
    max-width: 80% !important;
    width: 80%;
    margin-top: 100px !important;
}

.TSModalDailog_Thirteen {
    max-width: 60% !important;
    width: 60%;
}

.TSModalDailog_Fourteen {
    max-width: 40% !important;
    width: 40%;
}

.TSModalDailog_Fifteen {
    max-width: 22% !important;
    width: 22% !important;
    margin-top: 140px !important;
}

.TSModalDailog_Sixteen {
    max-width: 80% !important;
    width: 80%;
}