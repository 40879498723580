.pageContainer {
    width: 100%;
}

.pageHeading {
    margin: 0px;
    padding: 0rem 1rem;
    font-size: 18px;
    font-weight: bold;
}

.pageBox {
    background-color: #fff;
    border: none;
    box-shadow: 0 0 5px #a2a2a2;
    margin: 1rem;
    padding: 10px;
    border-radius: 2px;
}

.textDecorationNone {
    text-decoration: none;
}

.displayFlex {
    display: flex;
}

.listIcon {
    color: #f54e38 !important;
    font-size: 50px !important;
}

.addLink {
    margin-left: auto;
    text-decoration: none;
}

.primaryBtn {
    background-color: #16a0b6 !important;
    border-color: #16a0b6 !important;
    color: #fff !important;
}

    .primaryBtn:hover,
    .primaryBtn:focus {
        background-color: #16a0b6 !important;
        border-color: #16a0b6 !important;
        box-shadow: none !important;
    }

.addBtn {
    margin-top: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.plusIcon {
    font-size: 15px !important;
    margin-right: 0.5rem !important;
}

.marginTop1 {
    margin-top: 1rem;
}

.marginBottom1 {
    margin-bottom: 1rem;
}

.marginLeft {
    margin-left: 0.5rem !important;
}

.marginRight {
    margin-right: 0.5rem !important;
}

.marginLeftAuto {
    margin-left: auto !important;
    width: 1.5rem;
}

.tableContainer {
    margin: 1rem 0;
    height: 300px;
    display: contents;
}

.padding1 {
    padding: 1rem;
}

.headercaption {
    background: #5faed2 !important;
    color: white;
    font-weight: bold !important;
    font-size: 11pt !important;
}

.rdt_TableHeadRow {
    background: #5faed2 !important;
    color: white;
    font-weight: bold !important;
    font-size: 12pt !important;
}

.eUeqdG {
    overflow: hidden;
    white-space:pre-wrap !important;
    text-overflow:unset !important;
}


.dFsTSm {
    white-space: pre-wrap !important;
}

.idPHNo {
    display: flex;
    width: 100%;
    color: rgba(0,0,0,0.87);
    font-size: 12px;
    font-weight: 500;
}

.gZWIKX {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
    min-height: 52px;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.12);
    border-bottom-style: solid;
}

.kSJejC {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;
    -webkit-box-pack: center;
    justify-content: center;
}

.eaRkvd {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    border-left-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,0.12);
}

.gJhVev {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: inherit;
    -webkit-justify-content: inherit;
    height: 100%;
    width: 100%;
    outline: none;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
}
.tableContainer > .gelpCx {
    overflow: visible !important; /* To solve outcome context menu*/
    position: inherit; /* To solve export issue*/
}
